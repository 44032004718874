import { unparse } from "papaparse";

export function createAndDownloadCsv(rows: any[], nameWithoutExtension: string) {
  const csv = unparse(rows);
  createAndDownloadFile({
    text: csv,
    fileType: "text/csv;charset=utf-8",
    filename: `${nameWithoutExtension}.csv`,
  });
}

export function createAndDownloadFile(config: { text: string; fileType: string; filename: string }) {
  const blob = new Blob([config.text], { type: config.fileType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", config.filename);
  link.click();
}
